import { ICompany } from './company.interface';
import { ICustomer } from './customer.interface';
import { IInterruption } from './interruption.interface';
import { IRole } from './role.interface';

export interface IProject {
  id: string;
  number: string;
  commissioningAuthority: string;
  supervisoryAuthority: string;
  name: string;
  description: string;
  constructionTask: string;
  startDate: Date;
  endDate: Date;
  measurementsLocation: string;
  documentsLocation: string;
  geoTag: string;
  createdAt?: string | Date;
  customer?: ICustomer;
  companies?: ICompany[];
  companyIds: string[];
  interruptions?: IInterruption[];
  interruptionIds?: string[];
  role?: IRole;
  modifiedAt: Date | undefined;
  state?: 'running' | 'future' | 'past';
  status?: ProjectStatus;

  syncAt?: Date;
}

export enum ProjectStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived'
}

export interface IPatchProjectRequest {
  number: string;
  commissioningAuthority: string;
  supervisoryAuthority: string;
  name: string;
  description: string;
  constructionTask: string;
  startDate: string;
  endDate: string;
}

export interface IProjectStatusRequest {
  status: ProjectStatus;
}
