import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectsPage } from './components/projects/projects.page';
import { ProjectComponent } from '../project/components/project/project.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectsPage,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardPageModule)
      },
      {
        path: 'archive',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardPageModule)
      },
      {
        path: 'new',
        loadChildren: () => import('./modules/addproject/addproject.module').then(m => m.AddprojectPageModule)
      },
      {
        path: 'companies',
        loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesPageModule)
      },
      {
        path: 'not-found',
        loadChildren: () => import('core').then(m => m.NotFoundPageModule)
      },
      {
        path: ':projectId',
        component: ProjectComponent,
        children: [
          {
            path: '',
            redirectTo: 'diary',
            pathMatch: 'full'
          },
          {
            path: 'companies',
            loadChildren: () => import('core').then(m => m.ProjectCompaniesModule)
          },
          {
            path: 'diary',
            loadChildren: () => import('core').then(m => m.ProjectDiaryModule)
          },
          {
            path: 'export',
            loadChildren: () => import('core').then(m => m.ProjectExportModule)
          },
          {
            path: 'documents',
            loadChildren: () => import('core').then(m => m.ProjectDocumentsModule)
          },
          {
            path: 'members',
            loadChildren: () => import('core').then(m => m.ProjectMembersModule)
          },
          {
            path: 'plans',
            loadChildren: () => import('core').then(m => m.ProjectPlansModule)
          },
          {
            path: 'landing',
            loadChildren: () => import('core').then(m => m.ProjectLandingModule)
          },
          {
            path: 'details',
            loadChildren: () => import('core').then(m => m.ProjectDetailsModule)
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreProjectsRoutingModule {}
