import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IAuthority,
  ICompany,
  IDiaryEntry,
  IDiaryEntryIdsResponse,
  IEntry,
  IEntryDto,
  IEntryInterface,
  IEntryRequestType,
  IFCMToken,
  IGeneratePdfRequest,
  IImage as StateImage,
  IImage,
  IImageEntry,
  IImageEntryRequest,
  IInvitation,
  IInvitationPostRequest,
  ILicense,
  IMember,
  IMember as MemberState,
  INotification,
  IOrganization,
  IPage,
  IPatchProjectRequest,
  IPdf,
  IPdfTemplate,
  IPermission,
  IPostProjectRequest,
  IProject,
  IProject as ProjectOld,
  IProjectQueryParams,
  IProjectStatusRequest,
  IRole,
  IRoleRequest,
  IUser,
  OrganizationPermission
} from 'shared';
import { HttpEvent } from '@angular/common/http';
import { IDiarySearchRequestParams } from '../interfaces/diary-search-request-params';

@Injectable({
  providedIn: 'root'
})
export abstract class ResourceService {
  abstract postAbstractEntry<TRequest extends IEntryRequestType, TResponse extends IEntryInterface>(
    projectId: string,
    entry: TRequest
  ): Observable<TResponse>;
  abstract patchAbstractEntry<TRequest extends IEntryRequestType, TResponse extends IEntryInterface>(
    projectId: string,
    entryId: string,
    entry: TRequest
  ): Observable<TResponse>;

  abstract getAbstractEntries(
    projectId: string,
    params: IDiarySearchRequestParams,
    page: number,
    size: number
  ): Observable<IPage<IEntryInterface>>;

  abstract getEntryFile(projectId: string, entryId: string, preview: boolean): Observable<Blob>;

  abstract getInstanceName(): string;
  // projects
  abstract patchProject(projectId: string, project: IPatchProjectRequest): Observable<IProject>;
  abstract getProjects(queryParams?: IProjectQueryParams): Observable<IProject[]>;
  abstract getProject(projectId: string): Observable<IProject>;
  abstract setProjectStatus(projectId: string, request: IProjectStatusRequest): Observable<IProject>;

  // companies
  abstract getCompanies(query?: string): Observable<ICompany[]>;

  abstract getCompany(organization: IOrganization, companyId: string): Observable<ICompany>;

  // deleteCompany(companyId: string): Observable<void>;
  abstract patchCompany(company: ICompany): Observable<ICompany>;

  abstract postCompany(company: ICompany): Observable<ICompany>;

  //entries
  abstract getEntries(organization: IOrganization, project: IProject): Observable<IEntry[]>;

  abstract getEntry(projectId: string, entryId: string): Observable<IEntry>;

  abstract patchEntry(projectId: string, entryId: string, entry: IEntryDto): Observable<IEntryInterface>;

  abstract deleteEntry(projectId: string, entryId: string): Observable<void>;

  abstract postEntry(projectId: string, entry: IEntryDto): Observable<IEntryInterface>;

  abstract getImage(url: string, preview?: boolean): Observable<Blob>;

  abstract deleteImage(projectId: string, imageId: string): Observable<void>;

  abstract postImage(
    projectId: string,
    image: IImageEntryRequest,
    file: Blob,
    entryId?: string
  ): Observable<IImageEntry>;

  ///////////////////////////////

  abstract getAllRolesObs(): Observable<IRole[]>;
  abstract getProjectObs(organization: IOrganization, projectId: string): Observable<IProject>;
  abstract postProjectObs(project: IPostProjectRequest): Observable<IProject>;
  abstract getOrganizationProjects(organization: IOrganization): Observable<IProject[]>;
  abstract getOrganizationStatus(organization: IOrganization): Observable<any>;
  abstract getProjectMembersObs(projectId: string): Observable<MemberState[]>;
  abstract getProjectCompanies(projectId: string, query?: string): Observable<ICompany[]>;
  abstract getProjectCompaniesObs(projectId: string): Observable<ICompany[]>;
  abstract getUsersPermissions(userId: string): Observable<IPermission[]>;
  abstract getUserPermissionsNew(userId: string): Observable<OrganizationPermission[]>;
  abstract getRoleForProject(project: ProjectOld): Promise<IRole>;
  abstract deleteProjectMemberObs(projectId: string, memberId: string): Observable<void>;
  abstract postProjectMember(projectId: string, memberId: string, roles: IRole[]): Observable<MemberState>;
  abstract postProjectMemberRoleObs(projectId: string, memberId: string, roleId: string): Observable<IMember>;
  abstract deleteProjectMemberRoleObs(projectId: string, memberId: string, roleId: string): Observable<void>;
  abstract getAuthoritiesForProject(baseResRoute: string, projectId: string): Promise<IAuthority[]>;
  abstract getPossibleRolesForProject(organization: IOrganization, project: ProjectOld): Promise<IRole[]>;
  abstract getUsers(): Observable<IUser[]>;
  abstract getUser(userId: string): Observable<IUser>;
  abstract getUserDetails(userIds: string[]): Promise<IUser[]>;
  abstract getEntriesSpan(project: IProject, start: Date, end: Date): Observable<IEntryInterface[]>;
  abstract getImagesSpan(project: IProject, start: Date, end: Date): Observable<StateImage[]>;
  abstract getProjectUsers(project: ProjectOld): Promise<IMember[]>;
  abstract getRoles(): Observable<IRole[]>;
  abstract getRole(roleId: string): Observable<IRole>;
  abstract getUserRoles(userId: string): Observable<IRole[]>;
  abstract postUserRoles(userId: string, roles: { roles: string[] }): Observable<IRole[]>;
  abstract deleteUserRole(userId: string, roleId: string): Observable<void>;
  abstract postRole(role: IRoleRequest): Observable<IRole>;
  abstract patchRole(roleId: string, role: IRoleRequest): Observable<IRole>;
  abstract deleteRole(roleId: string): Observable<void>;
  abstract getPermissions(): Observable<IPermission[]>;
  abstract getInvitations(): Observable<IInvitation[]>;
  abstract deleteInvitation(invitationId: string): Observable<void>;
  abstract createInvitation(invitationRequest: IInvitationPostRequest): Observable<IInvitation>;
  abstract getCompanyProjects(organization: IOrganization, companyId: string): Observable<IProject[]>;
  abstract postProjectCompanies(project: IProject, companies: ICompany[]): Observable<void>;
  abstract getAllEntryIdsForProject(baseResRoute: string, projectId: string): Promise<IDiaryEntryIdsResponse[]>;
  abstract addDiaryEntry(organization: IOrganization, projectId: string, entry: IDiaryEntry): Promise<IDiaryEntry>;
  abstract patchDiaryEntry(organization: IOrganization, projectId: string, entry: IDiaryEntry): Promise<IDiaryEntry>;
  abstract closeDiaryEntry(project: ProjectOld, entry: IDiaryEntry): Promise<IDiaryEntry>;
  abstract getAllDatesForProject(baseResRoute: string, project_id: string): Promise<Date[]>;
  abstract getPossibleRoles(baseResRoute: string): Observable<IRole[]>;
  abstract postProjectUser(project: ProjectOld, user: IUser, role: IRole): Promise<IMember>;
  abstract getPdfs(projectId: string): Observable<IPdf[]>;
  abstract getPdf(projectId: string, pdfId: string): Observable<IPdf>;
  abstract getPdfTemplates(): Observable<IPdfTemplate[]>;
  abstract postPdf(baseResRoute: string, pdfRequest: IGeneratePdfRequest): any;
  abstract deletePdf(projectId: string, pdfId: string): Observable<void>;
  // abstract downloadPdf(project: ProjectOld, pdfId: string): any;
  abstract getPdfBlob(project: IProject, pdfId: string): Observable<Blob>;
  abstract getPdfBlobWithProgress(projectId: string, pdfId: string): Observable<HttpEvent<Blob>>;

  // abstract getDiaryEntry(project: ProjectOld, entryId: string): Promise<IDiaryEntry>;
  abstract closeEntry(projectId: string, entryId: string): Observable<IEntryInterface>;
  abstract addProject(project: ProjectOld): any;
  abstract getImagesObs(organization: IOrganization, project: IProject): Observable<StateImage[]>;
  abstract getAllImages(baseResRoute: string, project_id: string, includeEntryImages: boolean): Promise<IImage[]>;
  abstract getImageBlob(project: ProjectOld, imageId: string, resolution: 'lg' | 'sm'): Promise<Blob>;
  abstract getImageBlobObs(projectId: string, imageId: string, resolution: 'sm' | 'lg'): Observable<Blob>;
  abstract registerUser(): Observable<any>;
  abstract getRecentPdf(project: IProject, templateId: string): Observable<IPdf>;
  abstract getRecentPdfPromise(project: IProject, templateId: string): Promise<IPdf>;
  abstract getOrganization(id: string): Observable<IOrganization>;
  abstract getOrganizationMembers(organizationId: string): Promise<IUser[]>;
  abstract getOrganizationMembersObs(organizationId: string): Observable<IUser[]>;
  abstract getOrganizationUsers(organizationId?: string): Observable<IUser[]>;
  abstract getLicenses(organizationId: string): Observable<ILicense[]>;
  abstract getUserInfo(userId?: string): Observable<IUser>;
  abstract postNotificationToken(fcmToken: string): Promise<IFCMToken>;
  abstract getNotifications(): Promise<INotification[]>;
  abstract getNotification(notificationId: string): Promise<INotification>;
  abstract getOrganizations(): Observable<IOrganization[]>;
  abstract generateTemplatePdf(projectId: string, templateId: string): Observable<IPdf>;
}
