import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectDetailsRoutingModule } from './project-details-routing.module';
import { ProjectDetailsComponent } from './project-details.component';
import { SharedFeatherModule } from 'libs/shared/src/lib/modules/SharedFeather.module';
import { ButtonModule } from 'primeng/button';
import { ProjectEditDialogModule } from './project-edit-dialog/project-edit.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { AppMessageModule, ConfirmModule } from 'shared';
import { DividerModule } from 'primeng/divider';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProjectDetailsRoutingModule,
    SharedFeatherModule,
    ButtonModule,
    ProjectEditDialogModule,
    ProgressSpinnerModule,
    TooltipModule,
    ConfirmModule,
    DividerModule,
    AppMessageModule
  ],
  declarations: [ProjectDetailsComponent]
})
export class ProjectDetailsModule {}
