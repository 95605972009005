import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ErrorService,
  ResourceService,
  SelectProject,
  selectSelectedProject,
  UpdateProject,
  UpdateProjectSuccess
} from 'data-access';
import { DIALOG_SERVICE_KEY, DialogService, IProject, ProjectStatus, RESOURCE_KEY } from 'shared';

import {
  ProjectDetailsDialogData,
  ProjectEditDialogComponent
} from './project-edit-dialog/project-edit-dialog.component';
import { delay, timer } from 'rxjs';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  loading = true;
  public project?: IProject;
  archivingInProgress = false;
  // needed for comparison in *ngIf
  ProjectStatus = ProjectStatus;
  showArchivedSuccessMessage = false;
  showActivatedSuccessMessage = false;

  constructor(
    private store: Store,
    @Inject(DIALOG_SERVICE_KEY) private dialogService: DialogService,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.store.select(selectSelectedProject).subscribe(data => {
      this.project = data;
      console.log(this.project);
      this.loading = data === undefined || data === null;
    });
  }

  onEditProject() {
    this.dialogService.openDialog<ProjectEditDialogComponent, ProjectDetailsDialogData, IProject>(
      ProjectEditDialogComponent,
      { data: { project: this.project } }
    );
  }

  archiveProject() {
    this.archivingInProgress = true;
    this.showArchivedSuccessMessage = false;
    this.showActivatedSuccessMessage = false;

    this.resourceService.setProjectStatus(this.project!.id, { status: ProjectStatus.ARCHIVED }).subscribe({
      error: err => {
        this.errorService.showError('Projekt konnte nicht archiviert werden', err);
        this.archivingInProgress = false;
        this.showArchivedSuccessMessage = false;
        this.showActivatedSuccessMessage = false;
      },
      next: project => {
        this.store.dispatch(new UpdateProject({ project: project }));
        this.archivingInProgress = false;
        this.showArchivedSuccessMessage = true;
        this.showActivatedSuccessMessage = false;
        timer(3500).subscribe(() => {
          this.showActivatedSuccessMessage = false;
          this.showArchivedSuccessMessage = false;
        });
      }
    });
  }

  reactivateProject() {
    this.archivingInProgress = true;
    this.showArchivedSuccessMessage = false;
    this.showActivatedSuccessMessage = false;

    this.resourceService.setProjectStatus(this.project!.id, { status: ProjectStatus.ACTIVE }).subscribe({
      error: err => {
        this.errorService.showError('Projekt konnte nicht reaktiviert werden', err);
        this.archivingInProgress = false;
        this.showArchivedSuccessMessage = false;
        this.showActivatedSuccessMessage = false;
      },
      next: project => {
        this.store.dispatch(new UpdateProject({ project: project }));
        this.project = project;
        this.archivingInProgress = false;
        this.showActivatedSuccessMessage = true;
        this.showArchivedSuccessMessage = false;
        timer(3500).subscribe(() => {
          this.showActivatedSuccessMessage = false;
          this.showArchivedSuccessMessage = false;
        });
      }
    });
  }
}
