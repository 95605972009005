<div class="page">
  <div class="page-content">
    <p-progressSpinner *ngIf="loading; else ProjectDetailsTemplate"></p-progressSpinner>
  </div>
</div>

<ng-template #ProjectDetailsTemplate>
  <div class="details-row justify-content-between align-items-start no-wrap">
    <div class="details-container">
      <label>Projektname</label>
      <p>{{ project?.name }}</p>
    </div>
    <button
      pButton
      class="p-button-raised p-button-sm"
      label="Bearbeiten"
      icon="mat-outlined mat-edit"
      (click)="onEditProject()"></button>
  </div>

  <div class="details-row">
    <div class="details-container">
      <label>Laufzeit</label>
      <p>{{ project?.startDate | date : 'dd.MM.yyyy' }} - {{ project?.endDate | date : 'dd.MM.yyyy' }}</p>
    </div>
  </div>

  <div class="details-row">
    <div class="details-container">
      <label>Bauauftrag</label>
      <p>{{ project?.constructionTask }}</p>
    </div>
    <div class="details-container">
      <label>Projektnummer</label>
      <p>{{ project?.number }}</p>
    </div>
  </div>

  <div class="details-row">
    <div class="details-container">
      <label>Auftraggeber</label>
      <p>{{ project?.commissioningAuthority }}</p>
    </div>
    <div class="details-container">
      <label>Bauüberwachende Stelle</label>
      <p>{{ project?.supervisoryAuthority }}</p>
    </div>
  </div>

  <div class="details-row">
    <div class="details-container">
      <label>Projektbezeichnung</label>
      <p>{{ project?.description }}</p>
    </div>
  </div>

  <div class="flex-column flex justify-content-start mb-8" *ngIf="project?.status === ProjectStatus.ACTIVE">
    <p-divider class="mt-2"></p-divider>

    <div class="flex flex-row mt-3 mb-3 align-items-center">
      <h2 class="m-0 mr-2">Projekt Archivieren</h2>
    </div>
    <p id="archiveProjectDetailsContainer">
      Das Projekt wird in das <a [routerLink]="">Projektarchiv</a> verschoben. Archivierte Projekte bleiben weiterhin
      sichtbar und können eingesehen werden. Allerdings sind keine Änderungen oder Bearbeitungen mehr möglich. Falls
      eine weitere Bearbeitung erforderlich ist, kann das Projekt reaktiviert werden.
    </p>
    <ng-container [ngTemplateOutlet]="ArchivingSuccessMessage"></ng-container>
    <button
      pButton
      class="p-button-raised p-button-sm ml-auto"
      label="Archivieren"
      icon="mat-outlined mat-inventory-2"
      loadingIcon="pi pi-spin pi-spinner"
      [confirmDanger]="false"
      confirmLabel="Archivieren"
      confirmTitle="Projekt Archivieren"
      confirmMessage="Das Projekt wird in das Archiv verschoben. Es kann weiterhin eingesehen, aber nicht bearbeitet werden."
      [loading]="archivingInProgress"
      (onConfirmed)="archiveProject()"></button>
  </div>

  <div class="flex-column flex justify-content-start mb-8" *ngIf="project?.status === ProjectStatus.ARCHIVED">
    <p-divider class="mt-2"></p-divider>
    <div class="flex flex-row mt-3 mb-3 align-items-center">
      <h2 class="m-0 mr-2">Projekt Reaktivieren</h2>
    </div>
    <p id="activateProjectDetailsContainer">
      Dieses Projekt befindet wurde archiviert. Archivierte Projekte können eingesehen, aber nicht weiter bearbeitet
      werden. Es kann reaktiviert werden um es wieder in die aktiven Projekte zu verschieben.
    </p>
    <ng-container [ngTemplateOutlet]="ArchivingSuccessMessage"></ng-container>
    <button
      pButton
      class="p-button-raised p-button-sm ml-auto"
      label="Reaktivieren"
      icon="mat-outlined mat-refresh"
      loadingIcon="pi pi-spin pi-spinner"
      [confirmDanger]="false"
      confirmLabel="Reaktivieren"
      confirmTitle="Projekt Reaktivieren"
      confirmMessage="Das Projekt wird wieder in die aktiven Projekte verschoben"
      [loading]="archivingInProgress"
      (onConfirmed)="reactivateProject()"></button>
  </div>
</ng-template>

<ng-template #ArchivingSuccessMessage>
  <app-message
    id="archivedProjectSuccessMessage"
    *ngIf="showArchivedSuccessMessage"
    type="success"
    summary="Projekt"
    detail="wurde archiviert"
    (close)="showArchivedSuccessMessage = false">
  </app-message>
  <app-message
    id="activatedProjectSuccessMessage"
    *ngIf="showActivatedSuccessMessage"
    type="success"
    summary="Projekt"
    detail="wurde reaktiviert"
    (close)="showActivatedSuccessMessage = false">
  </app-message>
</ng-template>
